import { createContext, useMemo } from "react";
import useGraphQL from "@hooks/useGraphql";
import {
	IProductLabelCollection,
	IProductLabelsVariables,
	labelsByProductQuery,
} from "@components/ProductInfo/ProductLabels/product-labels.query";
import { EProductLabelSection } from "@components/ContentfulComponents/ComponentProductLabel/type";
import { IComposeHomeContext, IComposeHomeProvider } from "./type";

const initialValue = {
	labels: [],
};

export const ComposeHomeContext = createContext<IComposeHomeContext>(initialValue);

const ComposeHomeProvider = ({ children, value }: IComposeHomeProvider) => {
	const { data: labelsByProductData } = useGraphQL<
		IProductLabelCollection,
		IProductLabelsVariables
	>(labelsByProductQuery, {
		variables: {
			section: EProductLabelSection.Price,
		},
	});

	const contextValue = useMemo(() => {
		return { labels: labelsByProductData?.productLabelCollection?.items };
	}, [labelsByProductData]);

	return (
		<ComposeHomeContext.Provider value={value ?? contextValue}>
			{children}
		</ComposeHomeContext.Provider>
	);
};

export default ComposeHomeProvider;
