import { IBreadcrumbsData } from "@components/Breadcrumbs/breadcrumbs.types";
import { IDescriptionData } from "@components/ProductDescription/productDescription.types";
import { TContentTranslation } from "./translations.types";
import { IAvailabilityProduct } from "@shared/availability/types";

export interface IProductImage {
	imageOrder: number;
	id: string;
	url: string;
	name?: string;
	brand?: string;
}

export interface IProductVideo {
	videoId: string;
	url: string;
}

export interface ISticker {
	name: string;
	stickerId: number;
	image?: string;
}

export interface IPriceData {
	finalPrice: number;
	slashedPrice: number;
	priceLabel: string;
	discountPercentage: number;
	mrp: number;
	showSlash: boolean;
	sticker?: ISticker;
}

export interface IVariantItem {
	description: IDescriptionData;
	images: IProductImage[];
	modelId: number;
	additionalWeight: number;
	additionalPackagingWeight: number;
	additionalShippingFee: number;
	ean: string;
	itemDimension: IDimensions;
	id: number;
	name: string;
	price: number;
	priceForFront: IPriceData;
	itemQty: number;
	articles: IArticle[];
	type: string;
	disabled: boolean;
}
export interface IAggregateRatingReview {
	ratingCount: number;
	ratingAverage: number;
	firstName?: string;
	note?: number;
}

export interface IProduct {
	images?: IProductImage[];
	videos?: IProductVideo[];
	name: string;
	modelId: number;
	attribute: IAttribute;
	defaultArticleId: number;
	price: IPriceData;
	priceForFront: IPriceData;
	brand: string;
	breadcrumbs: IBreadcrumbsData[];
	description: IDescriptionData;
	madeIn: string;
	slugUrl: string;
	slugApi: string;
	sizes: string[];
	smartSize?: string;
	currentUrl: string;
	lifeCycle: number;
	articles: IArticle[];
	superModel: string;
	familyId: number;
	nature: INature;
	alternate: IAlternate;
	disabled: boolean;
	review: {
		count: number;
		averageRating: number;
	};
}

export interface IProductCarousel extends Pick<IProduct, "modelId" | "brand" | "superModel"> {
	articleId: number;
	description: Partial<IDescriptionData>;
	image: Pick<IProductImage, "id" | "url">;
	priceForFront: Pick<
		IPriceData,
		"slashedPrice" | "finalPrice" | "showSlash" | "discountPercentage" | "sticker"
	>;
	review: IAggregateRatingReview;
}

export interface IProductWithAvailability {
	product: IProduct;
	isAvailable?: boolean;
}
export interface IProductWithAvailabilityAndIndex {
	product: IProduct | IProductCarousel;
	index: number;
	isAvailable?: boolean;
	list?: string;
}

export interface IAlternate extends Partial<Record<string, string>> {
	fr?: string;
	en?: string;
}

export interface IArticle {
	sku: string;
	articleId: number;
	articleOrder: number;
	attribute: IAttribute;
	default: boolean;
	dimension: IDimensions;
	ean: string;
	lifeCycle: number;
	madeIn: string;
	modelId: number;
	price: IPrice;
	priceForFront: IPriceData;
	squQty: number;
	stockNotification: { notify: boolean; message: string };
	taxId: number;
	weight: {
		weight: number;
	};
	brand: string;
}

export interface IArticleList extends IArticle {
	description: Pick<IDescriptionData, "productName">;
	imageUrl: string;
	color: string;
	slug: string;
}

export interface IPrice {
	price: number;
	priceReduction: number;
}

export interface IAttribute {
	attributeValue: string;
	attributeName: string;
}

interface INature {
	natureId: number;
	natureName: string;
}

export interface IProductProps {
	product: Omit<IProduct, "review"> & { review: IAggregateRatingReview };
	locale: string;
	availabilityArticles: IAvailabilityProduct[];
}

export type TSlidePartialProps = Pick<IProduct, "images"> &
	Partial<Pick<IProduct, "name" | "brand">> & {
		translations?: TContentTranslation;
	};

export type TSlideProps = Pick<IProduct, "images" | "name" | "brand" | "videos"> & {
	translations?: TContentTranslation;
};

export interface IDimensions {
	height: number;
	length: number;
	width: number;
}

export interface IAvailabilityArticleSize {
	articleId: number;
	sku: string;
	size: string;
	isNotifyMeAvailable: boolean;
	isOutOfStock: boolean;
}

export interface IRedirectToProductUrl extends Pick<IProduct, "description" | "modelId"> {
	relativeUrl: string;
	productSlug?: string;
}

export interface IFormatAlternateSEOValues extends Pick<IProduct, "modelId" | "description"> {
	pagePath: string;
	locales?: string[];
	locale?: string;
}

export const isProductCarousel = (
	product: IProduct | IProductCarousel
): product is IProductCarousel => !!(product as IProductCarousel).articleId;
