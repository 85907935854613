import Head from "next/head";

interface IPropsJsonLd {
	json: Record<string, unknown>;
}

const makeJSONLD = (microData: string) => {
	return { __html: microData };
};

const JsonScriptSetter = ({ json }: IPropsJsonLd) => {
	const microData = JSON.stringify(json);
	return (
		<Head>
			<script type="application/ld+json" dangerouslySetInnerHTML={makeJSONLD(microData)} />
		</Head>
	);
};

export default JsonScriptSetter;
