import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";
import { EMappingIdCflDataType } from "@components/ContentfulComponents/ComponentMappingId/type";
import { IBlockCarouselCflData } from "@components/ContentfulComponents/BlockCarousel/type";
import {
	EReferenceToComponents,
	IReferenceCflData,
} from "@components/ContentfulComponents/ComponentReference/type";
import { IProductCarousel, IProductWithAvailability } from "@shared/types/product.types";
import { IProductImpression } from "@shared/datalayer/datalayer.types";
import { THomePageCflDataContent } from "@components/ContentfulComponents/ComposeHome/type";
import { buildCrossSellingProductDataLayer } from "@shared/datalayer/cross-selling-product-datalayer/cross-selling-product-datalayer";
import { findCarouselItemInfoById } from "@components/ContentfulComponents/ComponentCarouselItem/carousel-items.utils";
import { isCarouselItem } from "@shared/contentful/contentful.utils";

function removeTrailingUnderscores(input: string) {
	let endIndex = input.length - 1;
	while (endIndex >= 0 && input[endIndex] === "_") {
		--endIndex;
	}

	return input.slice(0, endIndex + 1);
}

export const formatTitleForDataLayerList = (input?: string | null, isAICarousel = false) => {
	const additionalPrefix = isAICarousel ? "reco_" : "";
	const prefixedInput = input ? `home_${additionalPrefix}${input}` : `home_undefined`;

	return removeTrailingUnderscores(
		prefixedInput
			.slice(0, 30)
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "") // Remove diacritical characters
			.replace(/[^a-z0-9]+/g, "_") // Replace any non-alphabetic or non-numeric character with underscore
	);
};

export const buildCarouselProductImpressions = (
	contentCarouselData: IBlockCarouselCflData,
	productCarousel: IProductCarousel[],
	contentTitle?: string
) => {
	return contentCarouselData.carouselItemsCollection.items.reduce(
		(acc: IProductImpression[], data, index) => {
			if (isCarouselItem(data) && data.mappingId?.idType === EMappingIdCflDataType.Product) {
				const productMatch = findCarouselItemInfoById({
					mappingId: data.mappingId,
					carouselInfo: productCarousel,
				}) as IProductCarousel;

				if (productMatch) {
					acc.push(
						buildCrossSellingProductDataLayer({
							product: productMatch,
							list: formatTitleForDataLayerList(contentTitle),
							index,
						})
					);
				}
			}

			return acc;
		},
		[]
	);
};

export const buildCarouselImpressionsDataLayer = (
	items: THomePageCflDataContent[],
	productCarouselInfo: IProductCarousel[],
	mostPopularProducts: IProductWithAvailability[]
) => {
	return items.reduce((acc: IProductImpression[], content) => {
		if (content.__typename === EBlockTypenames.Carousel) {
			acc.push(
				...buildCarouselProductImpressions(
					content as IBlockCarouselCflData,
					productCarouselInfo,
					content.title
				)
			);
		} else if (
			content.__typename === EComponentTypenames.Reference &&
			(content as IReferenceCflData).referenceTo === EReferenceToComponents.AICarousel
		) {
			mostPopularProducts.forEach((recommendation, index) =>
				acc.push(
					buildCrossSellingProductDataLayer({
						product: recommendation.product,
						isAvailable: recommendation.isAvailable,
						list: formatTitleForDataLayerList((content as IReferenceCflData).title, true),
						index,
					})
				)
			);
		}

		return acc;
	}, []);
};
