import { EBlockTypenames, ICollection, ISysCfl } from "@shared/contentful/contentful.types";
import { IAssetCflData } from "../ComponentAsset/type";
import { IAssetWithTextCflData } from "../ComponentAssetWithText/type";
import { IHighlightedTextCflData } from "../ComponentHighlightedText/type";
import { ILinkCflData } from "../ComponentLink/type";
import { IQaCflData } from "../ComponentQA/type";
import { IRichTextCflData } from "../ComponentRichText/type";
import { IReferenceCflData } from "../ComponentReference/type";
import { ITextAssetAndCtasCflData } from "../ComponentTextAssetAndCtas/type";

export enum EGridSize {
	Small = "small",
	Medium = "medium",
	Large = "large",
}

export enum ETitleAlignment {
	Left = "left",
	Center = "center",
}

export type TPagesContentBlockData =
	| IAssetCflData
	| IAssetWithTextCflData
	| IQaCflData
	| IRichTextCflData
	| IHighlightedTextCflData
	| ILinkCflData
	| IReferenceCflData
	| ITextAssetAndCtasCflData
	| IContentPagesContentBlockCflData;

export enum EContentPagesContentBlockCflDisplayOptions {
	CardAndImage = "homepages: group of card with image",
	ContentBlock = "static pages: block of content",
	FeaturedLinks = "homepages: group of featured links",
	Handwriting = "static pages: block of handwriting",
	Highlights = "static pages: block of key highlights",
	Images = "static pages: block of images",
	Mosaic = "static pages: group of block mosaic",
	TextImageCtas = "homepages: group of text image cta",
}

export enum EContentPagesContentBlockCflLayout {
	ImageBlock1_1_3 = "ImageBlock: 1-1-3",
	ImageBlock1_1_2 = "ImageBlock: 1-1-2",
	MosaicBlock2_3_4 = "MosaicBlock: 2-3-4",
	MosaicBlock1_2_4 = "MosaicBlock: 1-2-4",
	MosaicBlock1_1_2 = "MosaicBlock: 1-1-2",
	KeyHighlights1_3_3 = "KeyHighlights: 1-3-3",
	KeyHighlights1_2_4 = "KeyHighlights: 1-2-4",
}

export enum EContentPagesContentBlockCflBgColor {
	White = "white",
	Grey = "grey",
}

export interface IContentPagesContentBlockCflData extends ISysCfl {
	__typename: EBlockTypenames;
	identifier?: string;
	displayAs?: EContentPagesContentBlockCflDisplayOptions;
	title?: string;
	intro?: string;
	oldEntry?: string;
	layout?: EContentPagesContentBlockCflLayout;
	backgroundColor?: EContentPagesContentBlockCflBgColor;
	contentCollection?: ICollection<TPagesContentBlockData>;
}
