import { gql } from "graphql-request";

export const productLabelFragment = gql`
	fragment productLabel on ProductLabel {
		__typename
		identifier
		section
		label
		labelPriority
		tooltip
		icon
		borderColor
		bgColor
		fontColor
		mappingId {
			...categoryIdMapping
		}
	}
`;
