import { Fragment, useEffect, useMemo } from "react";

import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import {
	IHomeCarouselInfo,
	IHomeRecommendation,
	THomePageCflDataContent,
} from "@components/ContentfulComponents/ComposeHome/type";
import { EGridSize } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { IBlockCarouselCflData } from "@components/ContentfulComponents/BlockCarousel/type";
import dynamic from "next/dynamic";
import { IReferenceCflData } from "@components/ContentfulComponents/ComponentReference/type";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { buildCarouselImpressionsDataLayer } from "@components/ContentfulComponents/ComposeHome/HomeContent/HomeContent.utils";
import { IHeroBannerCarouselCflData } from "@components/ContentfulComponents/BlockHeroBannerCarousel/type";

interface IHomeContent {
	items: THomePageCflDataContent[];
	carouselInfo: IHomeCarouselInfo;
	recommendation: IHomeRecommendation;
	absoluteUrl?: string;
}

const DynamicRichText = dynamic(
	() => import("@components/ContentfulComponents/ComponentRichText/RichText.component")
);
const DynamicTextAssetAndCtas = dynamic(
	() =>
		import("@components/ContentfulComponents/ComponentTextAssetAndCtas/TextAssetAndCtas.component")
);
const DynamicContentPagesContentBlock = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ContentPagesContentBlock/ContentPagesContentBlock.component"
		)
);
const DynamicBlockCarousel = dynamic(
	() => import("@components/ContentfulComponents/BlockCarousel/BlockCarousel.component")
);

const DynamicReference = dynamic(
	() => import("@components/ContentfulComponents/ComponentReference/Reference.component")
);

const DynamicHeroBannerCarousel = dynamic(
	() =>
		import(
			"@components/ContentfulComponents/ComponentTextAssetAndCtas/HeroBanner/HeroBannerCarousel.component"
		)
);

const HomeContent = ({ items, absoluteUrl, carouselInfo, recommendation }: IHomeContent) => {
	const carouselImpressions = useMemo(() => {
		if (items) {
			return buildCarouselImpressionsDataLayer(
				items,
				carouselInfo?.productCarouselInfo ?? [],
				recommendation?.mostPopularProducts ?? []
			);
		}

		return [];
	}, [items, carouselInfo.productCarouselInfo, recommendation.mostPopularProducts]);

	useEffect(() => {
		globalDataLayer.customDataLayer?.dispatchData({
			productImpressions: carouselImpressions,
		});
	}, []);

	if (!items) return null;

	return (
		<>
			{items.map((item) => {
				if (!item?.__typename) return null;

				switch (item.__typename) {
					case EComponentTypenames.RichText:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicRichText
									data={item as IRichTextCflData}
									data-testid="HomeContent_rich-text"
								/>
							</Fragment>
						);
					case EComponentTypenames.TextAssetCtas:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicTextAssetAndCtas
									data={item as ITextAssetAndCtasCflData}
									absoluteUrl={absoluteUrl}
									data-testid="HomeContent_text-asset"
								/>
							</Fragment>
						);
					case EBlockTypenames.PagesContentBlock:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicContentPagesContentBlock
									data={item}
									absoluteUrl={absoluteUrl}
									gridSize={EGridSize.Large}
									recommendation={recommendation}
									data-testid="HomeContent_content-pages"
								/>
							</Fragment>
						);
					case EBlockTypenames.Carousel:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicBlockCarousel
									data={item as IBlockCarouselCflData}
									gridSize={EGridSize.Large}
									carouselInfo={carouselInfo}
									data-testid="HomeContent_block-carousel"
								/>
							</Fragment>
						);
					case EComponentTypenames.Reference:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicReference
									data={item as IReferenceCflData}
									recommendation={recommendation}
									data-testid="HomeContent_reference"
								/>
							</Fragment>
						);
					case EBlockTypenames.HeroBannerCarousel:
						return (
							<Fragment key={item.sys?.id}>
								<DynamicHeroBannerCarousel
									data={item as IHeroBannerCarouselCflData}
									id={item.sys?.id}
									data-testid="HomeContent_hero-banner-carousel"
								/>
							</Fragment>
						);
					default:
						return null;
				}
			})}
		</>
	);
};

export default HomeContent;
