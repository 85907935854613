import { TCategoryCarousel } from "@components/Category/category.types";
import { IHeroBannerCarouselCflData } from "@components/ContentfulComponents/BlockHeroBannerCarousel/type";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { THomePageCflDataContent } from "@components/ContentfulComponents/ComposeHome/type";
import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";
import { IAggregateRatingReview, IProduct, IProductCarousel } from "@shared/types/product.types";

export const formatDataProductsInfoCarousels = (
	data: IProduct & { review: IAggregateRatingReview }
): IProductCarousel | null => {
	if (!data) return null;

	return {
		articleId: data.articles?.[0]?.articleId ?? 0,
		brand: data.brand ?? "",
		description: {
			descriptionShort: data.description?.descriptionShort ?? "",
			productName: data.description?.productName ?? "",
			slug: data.description?.slug ?? "",
		},
		image: {
			id: data.images?.[0]?.id ?? "",
			url: data.images?.[0]?.url ?? "",
		},
		modelId: data.modelId ?? 0,
		priceForFront: {
			finalPrice: data.priceForFront?.finalPrice ?? 0,
			slashedPrice: data.priceForFront?.slashedPrice ?? 0,
			discountPercentage: data.priceForFront?.discountPercentage ?? 0,
			showSlash: data.priceForFront?.showSlash ?? false,
		},
		review: data.review ?? {},
		superModel: data.superModel ?? "",
	};
};

export const formatDataCategoriesInfoCarousels = (
	data: TCategoryCarousel
): TCategoryCarousel | null => {
	if (!data?.categoryId && !data?.slug) return null;

	return {
		categoryId: data.categoryId ?? "",
		slug: data.slug ?? "",
	};
};

export const findFirstBanner = (items: THomePageCflDataContent[]) => {
	if (!items) return;

	const firstHeroBanner = items.find(
		(item) => item.__typename === EBlockTypenames.HeroBannerCarousel
	) as IHeroBannerCarouselCflData;
	const firstTextAssetCtas = items.find(
		(item) => item.__typename === EComponentTypenames.TextAssetCtas
	) as ITextAssetAndCtasCflData;

	const firstHeroBannerUrl = firstHeroBanner?.contentCollection?.items?.[0]?.asset?.asset?.url;
	const firstTextAssetCtaUrl = firstTextAssetCtas?.asset?.asset?.url;

	return firstHeroBannerUrl || firstTextAssetCtaUrl;
};
