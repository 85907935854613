import { isEmpty } from "lodash";
import { GetServerSideProps, NextApiRequest, NextApiResponse } from "next";
import Head from "next/head";

import Layout from "@components/Layout/Layout.component";
import JsonScriptSetter from "@components/JsonScriptSetter/JsonScriptSetter.component";
import { IPageCflData, IStaticPageProps } from "@components/ContentfulComponents/ComposePage/type";
import ComposeHome from "@components/ContentfulComponents/ComposeHome/ComposeHome.component";
import {
	IHomeCarouselInfo,
	IHomePageCflData,
	IHomeRecommendation,
} from "@components/ContentfulComponents/ComposeHome/type";
import { retrieveCarouselBlockIds } from "@components/ContentfulComponents/BlockCarousel/utils";
import MenuSeo from "@components/Header/Components/MenuSeo/MenuSeo.component";
import { homepageQuery } from "@components/ContentfulComponents/ComposePage/home.query";

import { graphQLWithPreviewRequest } from "@hooks/useGraphql";

import { EDataLayerPageType } from "@shared/datalayer/datalayer.types";
import { normalizeRobot } from "@shared/contentful/slug.utils";
import { findFirstBanner } from "@shared/index/index.utils";
import {
	getCategoriesInfoCarousels,
	getProductsInfoCarousels,
} from "@shared/index/index.utils.server";
import { gtmBreadcrumbs } from "@utils/gtm.utils";
import { getMenuSeoLinks, IMenuSeoLink } from "@utils/seo.utils";
import ComposeHomeProvider from "@components/ContentfulComponents/ComposeHome/ComposeHomeProvider.component";
import { getMostPopularProductsForHomePage } from "@shared/recommendation/recommendation.utils.server";
import { isUserConnectedOnPage } from "@shared/auth/token.utils";

export interface IHomeProps
	extends Omit<IStaticPageProps<IPageCflData<IHomePageCflData>>, "staticContentJson"> {
	homepageJson: Record<string, string>;
	carouselInfo: IHomeCarouselInfo;
	recommendation: IHomeRecommendation;
	menuSeoLinks?: IMenuSeoLink[];
	region?: string;
}

export const getServerSideProps: GetServerSideProps = async ({
	locale,
	req: ctxReq,
	res: ctxRes,
	preview = false,
}) => {
	const req = ctxReq as NextApiRequest;
	const res = ctxRes as NextApiResponse;

	await isUserConnectedOnPage({ req, res });

	const data = await graphQLWithPreviewRequest({
		query: homepageQuery,
		variables: {
			slug: "/",
			locale,
		},
		preview,
	});

	if (!data?.pageCollection?.items?.[0] || "error" in data) {
		return {
			notFound: true,
		};
	}

	const menuSeoLinks = await getMenuSeoLinks(locale);

	const { title, seo, content } = data.pageCollection.items[0];

	const { description, noFollow, noIndex, title: metaTitle } = seo;

	const metas = {
		metaTitle: `${metaTitle}`,
		metaDescription: description,
		isMetaGeneral: true,
		fr: "",
		en: "",
	};

	const { productMappingIds, categoryMappingIds } = retrieveCarouselBlockIds(
		data.pageCollection.items[0].content?.contentCollection?.items
	);

	const productCarouselInfo = await getProductsInfoCarousels({
		mappingIds: productMappingIds,
		locale,
		req,
		res,
	});

	const categoryCarouselInfo = await getCategoriesInfoCarousels({
		mappingIds: categoryMappingIds,
		locale,
		req,
		res,
	});

	const mostPopularProducts =
		(await getMostPopularProductsForHomePage({
			req,
			res,
			locale,
		})) ?? [];

	const homepageJson = content?.json || {};

	return {
		props: {
			data,
			noFollow,
			noIndex,
			metas,
			homepageJson,
			title,
			carouselInfo: {
				productCarouselInfo,
				categoryCarouselInfo,
			},
			recommendation: {
				mostPopularProducts,
			},
			preview,
			menuSeoLinks,
		},
	};
};

const Home = ({
	data,
	noFollow,
	noIndex,
	metas,
	homepageJson,
	region,
	absoluteUrl,
	carouselInfo,
	preview,
	menuSeoLinks,
	recommendation,
}: IHomeProps) => {
	if (!data?.pageCollection?.items[0]) return null;

	const metaIndex = normalizeRobot("index", noIndex);
	const metaFollow = normalizeRobot("follow", noFollow);

	const robots = `${metaIndex},${metaFollow}`;

	const firstBanner = findFirstBanner(
		data.pageCollection.items[0]?.content?.contentCollection?.items
	);

	return (
		<>
			{firstBanner && (
				<Head>
					<meta property="og:image" content={firstBanner} key="banner-image" />
				</Head>
			)}
			<Layout
				gtmBreadcrumbs={gtmBreadcrumbs([], "")}
				pageType={EDataLayerPageType.Home}
				robots={robots}
				metas={metas}
				openGraphTitle={metas?.metaTitle}
				currentUrl={absoluteUrl}
				additionalDataLayerInfo={homepageJson}
				region={region}
				preview={preview}
			>
				{!isEmpty(homepageJson) && <JsonScriptSetter json={homepageJson} />}
				<MenuSeo menuSeoLinks={menuSeoLinks} absoluteUrl={absoluteUrl} />
				<ComposeHomeProvider>
					<ComposeHome
						data={data.pageCollection.items[0]}
						absoluteUrl={absoluteUrl}
						carouselInfo={carouselInfo}
						recommendation={recommendation}
					/>
				</ComposeHomeProvider>
			</Layout>
		</>
	);
};

export default Home;
