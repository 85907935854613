import { EComponentTypenames, ISysCfl } from "@shared/contentful/contentful.types";

export interface IReferenceCflData extends ISysCfl {
	__typename: EComponentTypenames;
	referenceTo: string;
	carouselType: string | null;
	title: string | null;
}

export enum EReferenceToComponents {
	Separator = "separator",
	AICarousel = "AI carousel",
}
