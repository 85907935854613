import { componentMappingIdFragment } from "@components/ContentfulComponents/ComponentMappingId/fragment";
import { productLabelFragment } from "@components/ContentfulComponents/ComponentProductLabel/fragment";
import { IProductLabelCflData } from "@components/ContentfulComponents/ComponentProductLabel/type";
import { ICollection } from "@shared/contentful/contentful.types";
import { gql } from "graphql-request";

export enum EProductLabelsIdentifiers {
	PriceDrop = "label_back-price-drop",
}
export interface IProductLabelsVariables {
	productId?: string;
	section?: string;
}

export interface IProductLabelCollection {
	productLabelCollection: ICollection<IProductLabelCflData>;
}

export const labelsByProductQuery = gql`
	${productLabelFragment}
	${componentMappingIdFragment}
	query labelsByProduct($productId: String, $locale: String, $section: String) {
		productLabelCollection(
			where: { mappingId: { mappingCodes_contains: $productId }, section_contains: $section }
			locale: $locale
		) {
			items {
				...productLabel
			}
		}
	}
`;
