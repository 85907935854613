import { EComponentTypenames } from "@shared/contentful/contentful.types";
import { IMappingIdCflData } from "../ComponentMappingId/type";

export enum EProductLabelSection {
	Price = "price",
	Size = "size",
	Incentive = "incentive",
}

export interface IProductLabelCflData {
	__typename: EComponentTypenames;
	identifier: string;
	section?: EProductLabelSection | null;
	label: string;
	labelPriority?: number | null;
	tooltip?: string;
	icon?: string | null;
	borderColor: string | null;
	bgColor: string | null;
	fontColor: string | null;
	mappingId?: IMappingIdCflData;
}
