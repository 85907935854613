import {
	IProductWithAvailability,
	IProductWithAvailabilityAndIndex,
	isProductCarousel,
} from "@shared/types/product.types";
import {
	EDataLayerEventType,
	EDataLayerListType,
	IDataLayerCrossSellingImpressions,
} from "@shared/datalayer/datalayer.types";
import { findOriginalPrice } from "@shared/datalayer/cart-datalayer/cart-datalayer";
import { EAvailabilityStatus } from "@shared/availability/types";

interface IDataLayerMultipleCrossSellingImpressions {
	dataLayerList: EDataLayerListType;
	crossSellingProductsWithAvailability: IProductWithAvailability[];
}

interface IMultipleCrossSellingDataLayer {
	crossSellingList: IDataLayerMultipleCrossSellingImpressions[];
	isEvent?: boolean;
}

export const formatStockAvailability = (isAvailable?: boolean) => {
	if (typeof isAvailable === "boolean") {
		return isAvailable ? EAvailabilityStatus.InStock : EAvailabilityStatus.OutOfStock;
	}
	return EAvailabilityStatus.InStock;
};

export const buildCrossSellingProductDataLayer = ({
	product,
	isAvailable,
	list,
	index,
}: IProductWithAvailabilityAndIndex) => {
	return {
		id: isProductCarousel(product)
			? product.articleId.toString()
			: product?.articles?.[0]?.articleId?.toString(),
		list,
		position: index,
		supermodel: {
			id: product?.superModel,
		},
		model: {
			id: product?.modelId?.toString(),
		},
		availability: {
			warehouse: formatStockAvailability(isAvailable),
		},
		price: {
			currency: "CAD",
			original: findOriginalPrice(product?.priceForFront) ?? "0",
			current: product?.priceForFront?.finalPrice?.toFixed(2),
		},
		brand: product?.brand,
	};
};

export const buildCrossSellingProductImpressionsDataLayer = ({
	crossSellingList,
	isEvent = false,
}: IMultipleCrossSellingDataLayer): IDataLayerCrossSellingImpressions | undefined => {
	if (!crossSellingList?.length) return;
	const productImpressions = crossSellingList.flatMap(
		({ dataLayerList, crossSellingProductsWithAvailability }) => {
			return crossSellingProductsWithAvailability?.map((item, idx) =>
				buildCrossSellingProductDataLayer({ ...item, list: dataLayerList, index: idx })
			);
		}
	);

	return {
		productImpressions,
		...(isEvent && { event: EDataLayerEventType.ProductImpressions }),
	};
};

export const buildCrossSellingProductClickDataLayer = (
	dataLayerInfo: IProductWithAvailabilityAndIndex
) => {
	if (!dataLayerInfo) return;

	return {
		event: EDataLayerEventType.ProductClick,
		productClicks: [buildCrossSellingProductDataLayer(dataLayerInfo)],
	};
};
