import { IBreadcrumbsData } from "@components/Breadcrumbs/breadcrumbs.types";
import getConfig from "next/config";

const {
	publicRuntimeConfig: { host },
} = getConfig();

export const gtmBreadcrumbs = (breadcrumbs: IBreadcrumbsData[], locale: string) => {
	const links = breadcrumbs?.map((breadcrumb) => {
		return {
			title: breadcrumb?.name,
			url: `${host}/${locale}/${breadcrumb?.categoryId}/${breadcrumb?.slug}`,
		};
	});

	const breadcrumbsLinks = {
		links: [
			{
				title: "Home",
				url: `${host}/${locale}/`,
			},
			...links,
		],
		count: breadcrumbs?.length,
	};
	return JSON.stringify(breadcrumbsLinks);
};
