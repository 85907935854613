import { isEmpty } from "lodash";
import React from "react";

import HomeContent from "@components/ContentfulComponents/ComposeHome/HomeContent/HomeContent.component";
import { IPageContent } from "@components/ContentfulComponents/ComposePage/type";
import {
	IHomeCarouselInfo,
	IHomePageCflData,
	IHomeRecommendation,
} from "@components/ContentfulComponents/ComposeHome/type";

import styles from "./compose-home.module.scss";

interface IProps {
	data: IPageContent<IHomePageCflData>;
	absoluteUrl: string;
	carouselInfo: IHomeCarouselInfo;
	recommendation: IHomeRecommendation;
}

const ComposeHome = ({ data, absoluteUrl, carouselInfo, recommendation }: IProps) => {
	if (!data || isEmpty(data)) return null;

	return (
		<div className={styles.innerWrapper} data-testid="ComposeHome_wrapper">
			<HomeContent
				items={data.content?.contentCollection?.items}
				absoluteUrl={absoluteUrl}
				carouselInfo={carouselInfo}
				recommendation={recommendation}
			/>
		</div>
	);
};

export default ComposeHome;
