import { memo } from "react";

import { IMenuSeoLink } from "@utils/seo.utils";

interface IMenuSeoProps {
	menuSeoLinks?: IMenuSeoLink[];
	absoluteUrl?: string;
}
const MenuSeo = ({ menuSeoLinks, absoluteUrl }: IMenuSeoProps) => {
	if (!menuSeoLinks?.length) return null;

	return (
		<div className={"hidden"}>
			{menuSeoLinks.map(({ label, url, sys }: IMenuSeoLink) => {
				if (!url) return;
				return (
					<a href={`${absoluteUrl ?? ""}/${url}`} key={sys?.id}>
						{label}
					</a>
				);
			})}
		</div>
	);
};

export default memo(MenuSeo);
