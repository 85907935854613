import { EComponentTypenames } from "@shared/contentful/contentful.types";

export enum EMappingIdCflDataType {
	Category = "category id",
	Product = "product id",
	SeoCategory = "SEO category id",
}

export interface IMappingIdCflData {
	__typename: EComponentTypenames;
	identifier?: string;
	idType?: EMappingIdCflDataType;
	mappingCodes?: string;
}
