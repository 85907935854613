import { EIcon } from "@components/Icon/Icon.component";

export function normalizeRobot(action: string, hideAction: boolean) {
	return hideAction ? `no${action}` : action;
}

export const getContentfulIcon = (icon: string | undefined): EIcon | undefined => {
	switch (icon) {
		case "plus":
			return EIcon.Plus;
		case "arrow-left":
			return EIcon.ArrowLeft;
		case "arrow-right":
			return EIcon.ArrowRight;
		case "facebook":
			return EIcon.Facebook;
		case "youtube":
			return EIcon.Youtube;
		case "linkedin":
			return EIcon.Linkedin;
		case "external-link":
			return EIcon.ExternalLink;
		case "phone":
			return EIcon.Phone;
		case "map-pin":
			return EIcon.MapPin;
		case "mail":
			return EIcon.Mail;
		case "alert-circle":
			return EIcon.AlertCircle;
		case "rotate-ccw":
			return EIcon.RotateCcw;
		case "shopping-bag":
			return EIcon.ShoppingBag;
		case "award":
			return EIcon.Award;
		case "tool":
			return EIcon.Tool;
		case "shopping-cart":
			return EIcon.Cart;
		case "check-circle":
			return EIcon.CheckCircle;
		default:
			return undefined;
	}
};
